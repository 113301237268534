<template>
  <div
    class="discountRequestsDashboard js-tabBox discountRequestsDashboard--admin"
  >
    <div class="discountRequestsDashboard__header">
      <div class="discountRequestsDashboard__title">Discount requests</div>
      <router-link class="discountRequestsDashboard-export__button" to="">
        <span @click="exportToExcel()" class="text">Export </span>
      </router-link>
    </div>
    <div class="discountRequestsDashboard__content">
      <div class="discountRequestsDashboard__content-item js-tabContentItem">
        <div class="discountRequestsDashboard__table">
          <Pagination
            :pageCount="discountRequestsListPageCount"
            :rowCount="discountRequestsListCount"
            @pageClick="onDiscountRequestsPageClick"
          />
          <div class="discountRequestsDashboard__table-header">
            <div class="discountRequestsDashboard__table-cell">№</div>
            <div class="discountRequestsDashboard__table-cell">CreatedOn</div>
            <div class="discountRequestsDashboard__table-cell">User</div>
            <div class="discountRequestsDashboard__table-cell">Code</div>
          </div>
          <div class="discountRequestsDashboard__table-body js-customScroll">
            <vue-scroll :ops="ops">
              <div
                v-for="(item, index) in discountRequestsList"
                :key="item.id"
                class="discountRequestsDashboard__table-row js-acc-item"
              >
                <div
                  class="discountRequestsDashboard__table-row-header js-acc-title"
                >
                  <div class="discountRequestsDashboard__table-cell">
                    <div class="discountRequestsDashboard__table-index">
                      {{ discountRequestsList.length - index }}
                    </div>
                  </div>
                  <div class="discountRequestsDashboard__table-cell">
                    <span>{{ getDisplayDateTime(item.createdOn) }}</span>
                  </div>
                  <div class="discountRequestsDashboard__table-cell">
                    <a
                      @click="openAdditionalDetails(item)"
                      style="color: darkblue">
                      {{ `${item.firstName} ${item.lastName}` }}
                    </a>
                  </div>
                  <div class="discountRequestsDashboard__table-cell">{{ item.code }}</div>
                </div>
              </div>
            </vue-scroll>
          </div>
        </div>
      </div>
    </div>
    <FlyerShortInfoPopup :flyerInfo="flyerInfo" @clearInfo="clearInfo"/>
  </div>
</template>

<script>
import { reportsAPI } from '@/api/reports';
import { Tabs } from '@/js/plugins/tabs';
import Pagination from '@/components/common/Pagination/Pagination';
import './DiscountRequests.styl';
import moment from 'moment';
import FlyerShortInfoPopup from '@/components/common/modals/FlyerShortInfoPopup.vue';

export default {
  name: 'DiscountRequests',
  data: () => ({
    tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
    tabSelected: 'discountRequests',
    flyerInfo: {},
  }),
  computed: {
    discountRequestsList () {
      return this.$store.state.offers.discountRequestsList;
    },
    discountRequestsListPageCount () {
      return this.$store.state.offers.discountRequestsListPageCount;
    },
    discountRequestsListCount () {
      return this.$store.state.offers.discountRequestsListCount;
    },
  },
  mounted () {
    this.tabs.addActiveForMouted();
  },
  methods: {
    moment,
    async exportToExcel () {
      try {
        const { data } = await reportsAPI.discountRequests();
        const downloadLink = document.createElement('a');
        const blob = new Blob(['\ufeff', data]);
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download =
          'discountRequests_report_' +
          new Date().toLocaleDateString().replaceAll('/', '-') +
          '.csv';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (e) {
        console.log(e);
      }
    },
    async onDiscountRequestsPageClick (data) {
      await this.$store.dispatch('getDiscountRequestsList', data);
    },
    getDisplayDateTime (dateString) {
      const options = { timeZone: 'America/New_York', hour12: false };
      let date = new Date(dateString);
      date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      return dateString === null ? '' : date.toLocaleString('en-US', options);
    },
    async openAdditionalDetails (info) {
      this.flyerInfo = {
        flyerId: info.accountId,
        flyerName: `${info.firstName} ${info.lastName}`,
        flyerEmail: info.email,
        flyerMobile: info.mobile,
      };

      this.$modal.show('FlyerShortInfoPopup');
    },
    clearInfo () {
      this.flyerInfo = {};
    },
  },
  components: {
    Pagination,
    FlyerShortInfoPopup,
  },
};
</script>